.contentArea {
    margin-top: 5vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentTitle {
    margin-top: 10vh;
}

.credentials {
    position: absolute;
    bottom: 2vh;
    left: 15vw;
}

.credentials h5.ant-typography, .ant-typography h5 {
    margin-bottom: 0;
}

.editOrderForm label {
    font-weight: 800;
}
