.selectMethodWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.navigation-btn {
    margin-top: 10vh;
}

.importWrapper {
    margin-top: 5vh;
}
