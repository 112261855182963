.shipmentsContainer > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > div{
    /* width: 90vw !important;*/
    width: auto !important;
    max-width: 1400px !important;
}

.shipmentsContainer .ant-form-item{
    margin-bottom: 5px;
}

.shipmentCard .ant-pro-card-header{
    background-color: #f0f0f0;
}

.numberItem > .ant-row > .ant-form-item-label{
    position: relative;
    top: 8px;
}

.flexToRow .ant-form-item{
    flex-direction: row;
}

.flexToRow .ant-form-item .ant-form-item-row{
    display: inline-block;
    width: 100%;
    text-align: left;
}

.flexToRow .ant-form-item .ant-form-item-control{
    display: inline-block;
    min-width: 60%;
    width: auto;
    line-height: 1;    
}

.flexToRow .ant-form-item .ant-form-item-control .ant-typography{
    margin-bottom: 0;    
}

.flexToRow .ant-form-item .ant-form-item-control .ant-form-item-control-input{
    position: relative;
    top: 2px;   
}

.flexToRow .ant-form-item .ant-form-item-label{
    display: inline-block;
    padding: 0 20px 0 0;   
}

.flexToRow .ant-form-item .ant-form-item-label > label{
    height: 32px;
}


