.priceTable{
    width: 100%;  
}

.priceTable .priceTitle{
    width: 25%; 
    text-align: right;
}

.priceTable .priceContent{
    width: 55%; 
    text-align: left;   
}

.priceTable .priceContent .ant-form-item{
    margin-bottom: 0;
}

.priceTable .price{
    width: 20%; 
    text-align: right;
    font-weight: 600;
}

.priceTable td{
    padding: 10px 20px;
}

.priceTable .trBorderBottom{
    border-bottom: 1px solid rgba(0,0,0,.12);
}

.servicePriceClasses{
    margin-top: 20px;
}

.servicePriceRow .ant-form-item .ant-form-item-label{
    min-width: 25%;
    text-align: right;
    padding-right: 50px;                    
}  

.servicePriceRow .ant-form-item .ant-form-item-control input[type=text]{
    padding-left: 0;
}

.totalPriceTableRow{
    margin-top: 30px;
}

.totalPriceTableRow .totalPriceTable{
    width: 100%;
}

.totalPriceTableRow .totalPriceTable .priceItem{
    font-size: 16px;
    text-align: right;
}

.totalPriceTableRow .totalPriceTable .priceItem .priceTitle{
    font-weight: 600;
    padding-right: 10px;
}

.totalPriceTableRow .totalPriceTable .total .priceItem{
    font-size: 18px;
}