.App {
  text-align: center;
  /* background-color: #282c34; */
  min-height: 100vh;

}

.App-header {
  /* background-color: #282c34; */
  width: 100vw;
  height: 10vh;
  /*font-size: calc(10px + 2vmin);*/
  /* color: white; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
}

.App-content {
  min-height: 85vh;
  padding: 2vh 4vw;
  position: relative;
}

.App-content > .ant-steps{
  width: 70%;
  margin: 0 auto;  
}

.App-footer {
  width: 100vw;
  height: 5vh;
}
