.spanRequired{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
}

.spanAddon{
    font-weight: 600;
}

.flexToRow.left {
    text-align: left;
}

.flexToRow.itemGroupContainer {
    margin-top: 20px;
}

.flexToRow.itemGroupContainer .ant-form-item .ant-form-item-control{
    min-width: auto;
}

.flexToRow.itemGroupContainer .packageTypeSelect{
    position: relative;
    top: 4px;    
}

.flexToRow.itemGroupContainer .packageTypeSelect .ant-select{
    min-width: 105px;
}

.flexToRow.itemGroupContainer .sizeSelect .ant-select{
    min-width: 275px;
}

.flexToRow.itemGroupContainer .itemSelect .ant-select{
    min-width: 465px;
}